.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: TotalSans,Arial,Helvetica,sans-serif!important;
}

.Main {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  margin: 12px;
  font-family: TotalSans,Arial,Helvetica,sans-serif;
}

.Main-content {
  display: flex;
  flex-direction: column;
  max-width: 1140px;
  width: 100%;
}

.Welcome {
  text-align: left;
  margin: 1em 0;
  font-size: 32px;
}

label {
  width: 210px;
  font-size: 18px;
}

input {
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 34px;
  width: 270px;
  padding: 0 12px;
}

button {
  background: #4714c7;
  border: 0 none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: block;
  padding: 0.3em 0;
  text-align: center;
  text-decoration: none;
  width: 243px;
  font: inherit;
  font-size: 20px;
}

button:disabled {
  background-color: grey;
  cursor: auto;
}
