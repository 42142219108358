.Form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.Form-header {
  background-color: #ebebeb;
  padding: 0.5em;
  font-size: 24px;
}

.Form-inputs {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 1em 2.5em;
}

.Form-input {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.Form-submit {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.Input-error {
  border: 1px solid red;
}

.Error {
  color: red;
  margin-left: 16px;
}
