.App-header {
  display: flex;
  background-color: #0c1870;
  min-height: 86px;
  justify-content: center;
  flex-wrap: wrap;
}

.Header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 940px;
  width: 100%;
}

.Title {
  color: #fff;
  font-weight: normal;
  font-size: 30px;
}
