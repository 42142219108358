.Status {
  display: flex;
  place-self: center;
  font-family: TotalSans,Arial,Helvetica,sans-serif;
  margin: 24px;
}

.Status-heading {
  font-size: 30px;
}

